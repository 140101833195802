<template>
    <div class="tab-content" >
       <AdvChart securityType="FUND" :selectedSymbol="selectedSymbol" key="fundChart"  ref="advChart" />
		
    </div>
	<AttributionFooter authors='quoteMedia' />
</template>

<script>
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';

import AdvChart from '../charts/highcharts/AdvChart';
import AttributionFooter from '../footer/AttributionFooter.vue';


export default {
    name: "Charting",
    data() {
		return {
			justMounted: false,
			active: false,
			
		}
	},
    components : {
        AdvChart,
		AttributionFooter
    },
    computed: {
        ...mapState(['selectedEquity']),
		securityId() {
			return this.$store.state.selectedFund.fundId;
		},
		selectedSymbol() {
				return SecurityFormatter.getSymbolViaCdnExchanges(this.$store.state.selectedFund);
		},
    },
    
    mounted() {
		
		this.justMounted = true;
		console.debug(this.$options.name + " mounted");
	},
	activated() {
		this.active = true;
		console.debug(this.$options.name + " activated");
		
		this.justMounted = false;
	},
	deactivated() {
		this.justMounted = false;
		this.active = false;
        console.debug(this.$options.name + " dectivated");
	},
    methods: {
        
    }
}
</script>
