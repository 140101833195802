<template> 
    <Dialog header="Select Date Range" v-model:visible="display" :modal="true" :closable="true" class='date-range-modal' @hide="close">
        <Calendar v-model="dateRange" :inline="true" selectionMode="range" />
        <template #footer>
            
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="OK" class='accept p-button-raised p-button-rounded' @click='doCallback' />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import Calendar from 'primevue/calendar';

export default {
    name: 'SupportTicketModal',
	data() {
		return {
			display: false,
            dateRange: [],
            callback: null,
		}
	},
	components: {
		Dialog,
        Calendar
	},

	methods: {
        open(callback) {
            this.callback = callback;
            this.display = true;
            this.dateRange = [];
            
        },

		close() {
			this.display = false;
            
            
		},

       
        doCallback() {
            if( this.callback) {
                this.callback(this.dateRange);
            }
            this.close();
            
            
           
                
        },
       
        
	}
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.date-range-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 30rem;
	box-shadow: none;
}

.date-range-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.date-range-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.date-range-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
}
.date-range-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.date-range-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.date-range-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
.date-range-modal .subject-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.date-range-modal .subject-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.date-range-modal .description-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.date-range-modal .description-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    margin: 15px 0px 25px;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.date-range-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .date-range-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

 .date-range-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.date-range-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .date-range-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.date-range-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .date-range-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }
</style>