<template>
    <div style="position: relative;">
    <MoreOptionsMenu class='more-options' @click="toggleMenu" style="float:right;"/>
	<Menu ref="menu" :model="chartMenuItems" :popup="true" />
    <highcharts  :options="chartOptions" :constructor-type="'stockChart'" ref="chartComponent" class="advChart card" style="padding: 10px 0px 0px; margin: 0px 0px 20px 0px; border-radius: 16px;"></highcharts>
         <div class="card">
            <div class='metrics-header-row'> 
                <span class="statement-title p-text-bold">Control Panel</span>   
                <div class="header-options">       
                <span class="p-input-icon-right">
                        <AutoComplete placeholder="Add a security" ref="advSecSearch" id="advSecSearch" field="name" forceSelection v-model="selectedAsset" 
                            :suggestions="filteredAssets" @complete="searchAssets($event)" 
                            @item-select="onAssetSelected($event)"  optionGroupLabel="label" optionGroupChildren="items" 
                            style="border-radius:10px;"  @click="onInputBarClick" :disabled="addDisabled">
                            <template #item="slotProps">
                                
                                <span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
                                <small>
                                    {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                                    {{ (slotProps.item.exchangeSymbol ? " - "+getExchange(slotProps.item.exchangeSymbol): "")}}
                                </small>
                            </template>
                        </AutoComplete>
                        <i class="pi pi-search" />
                        <Button icon="pi pi-angle-down"  class="p-inputgroup-addon adv-drop-button" @click="toggleFilterMenu" style="float:right;border-top-left-radius:0px;border-bottom-left-radius:0px;border-top-right-radius:8px;border-bottom-right-radius:8px;"/> 
                        <Menu class='filter-dropdown' ref="filterMenu" :model="searchTypes" :popup="true" >
                            <template #item="{ item }">
                                <template v-if="item.label == 'Filters'">
                                    <span class="p-submenu-header">{{ item.label }}</span>
                                </template>
                                
                                <template v-else>
                                    <a class="p-menuitem-link" @click="selectFilter(item, $event)">
                                        <div class='menuitem-row'>
                                            <span class="p-menuitem-text">{{ item.label }}</span>
                                            <span class='select-icon' v-if="item.selected"><i class="pi pi-check-circle"></i></span>
                                        </div>
                                    </a>
                                </template>
                            </template>
                        </Menu>
                        

                    </span>
                    <span style="float:right;margin-right:2rem;height:1.5rem;" class="live-update">Live Updates:<InputSwitch v-model="liveUpdates" @change="onToggleLiveUpdate" 
                        style="vertical-align: middle;margin-left: 1rem;"/></span>
                </div>
            </div>
        
            <DataTable :value="graphAssets" :loading="loadingData"  class="p-datatable-sm data-point-table" id='advDataTable' 
				:scrollable="true" scrollHeight="calc(100vh - 310px)" scrollDirection="horizontal" dataKey="id" 
				:metaKeySelection="false"  ref="table"
				>
				
				<Column field="symbol" header="Symbol"></Column>
				<Column header="Name">
					<template #body="slotProps">
						{{formatName(slotProps.data)}}
					</template>
				</Column>
                <Column header="Show Volume">
                    <template #body="slotProps">
                    <InputSwitch v-model="slotProps.data.showVolume" @change="onToggleVolume" v-if="!slotProps.data.marketIndexId"/>
                   
                    </template>
                </Column>
				<Column  header="Actions">
					<template #body="slotProps">
						<Button icon="pi pi-trash" @click="removeAsset(slotProps.data)" class="delete-note-button" v-if="notSelectedAsset(slotProps.data)"/>
					</template>
				</Column>
			
			</DataTable>
        </div>
    </div>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="50"/>
    <DateRangeModal ref="customDateRangeModal"/>
</template>
<script>



import MoreOptionsMenu from '../../menu/MoreOptionsMenu';
import SnapshotDialog from '../../modal/SnapshotDialog.vue';
import InputSwitch from 'primevue/inputswitch';
import DataService from '../../../service/DataService';
import ResearchService from '@/service/ResearchService.js';
import AutoComplete from 'primevue/autocomplete';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import format from 'date-fns/format';
import add from 'date-fns/add';
import addBusinessDays from 'date-fns/addBusinessDays';
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import isSunday from 'date-fns/isSunday'
import isSaturday from 'date-fns/isSaturday'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import isAfter from 'date-fns/isAfter'
import numeral from 'numeral';
import DateRangeModal from '../../modal/DateRangeModal.vue';
import SecurityFormatter from '../../../common/SecurityFormatter';

export default {
    name: 'AdvChart',
    props: {
        securityType: {
            type: String,
            required: true,
        },
        selectedSymbol: {
            type: String,
            required: true,
           
        },
    },
    components: {
        
        MoreOptionsMenu,
        SnapshotDialog,
        AutoComplete,
        DataTable,
        Column,
        InputSwitch,
        DateRangeModal,
    },
    

    data() {
        return {
            displaySnapshot: false,
            snapshotTarget: null,
            currentRange: "Y",
            currentSymbol: "",
            liveUpdates: true,
            loadingData: false,
            graphAssets: [],
            dataPointToGraph: "price",
            filteredAssets : [],
			selectedAsset: {name:""},
			searchTypes: [
                {
                    label: 'Filters',
                    items: [
                        {label: 'Equities', code: 'O1', selected: false},
                        {label: 'Funds', code: 'O2', selected: false},
                        {label: 'Indices', code: '03', selected: false},
                        {label: 'Market', code: 'O0', selected: true},
                        
                    ]
                }
			],
			searchType: null,


            chartOptions : { 
               yAxis: this.getYAxisConfig(),
               xAxis: this.getXAxisConfig(),

                tooltip: {
                    shape: 'square',
                    headerShape: 'callout',
                    borderWidth: 0,
                    shadow: false,
                    
                    formatter: this.getFormatterFunction(),
                    
                    split: true,
                    positioner: this.getPositionerFunction(),
                },
                 legend: {
                    enabled: true,
                    verticalAlign: 'top',
                },

                navigator: {
                    maskFill: 'rgba(51,204,153,0.15)',
                    enabled: false
                },
                scrollbar: {
                    enabled: true,
                    showFull: false,
                    height: 0,
                },
                chart: {
                    zoomType: 'x'
                },
                
                rangeSelector: {
                    allButtonsEnabled: true,
                    selected: 5,
                    inputEnabled: true,
                    verticalAlign: 'bottom',
                   
                    buttonTheme: { // styles for the buttons
                        fill: 'none',
                        stroke: '#32364E',
                        'stroke-width': 1,
                        r: 2,
                        style: {
                            color: '#32364E',
                            
                        },
                        states: {
                            hover: {
                                 fill: '#32364E',
                                style: {
                                    color: 'white'
                                }
                            },
                            select: {
                                fill: '#32364E',
                                style: {
                                    color: 'white'
                                }
                            }
                            
                        }
                    },
                    inputBoxBorderColor: '#32364E',
                    inputBoxWidth: 120,
                    inputBoxHeight: 18,
                    inputStyle: {
                        color: '#32364E',
                        
                        borderRadius: '3px',
                    },
                    labelStyle: {
                        color: '#32364E',
                        fontWeight: 'bold'
                    },

                    buttons: [
                     {
                        type: 'day',
                        count: 1,
                        text: 'D',
                        events: {
                            click: () => {
                                this.currentRange = "D";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                             }
                         },
                        
                    },
                     {
                        type: 'day',
                        count: 5,
                        text: '5D',
                         events: {
                             click: () => {
                                this.currentRange = "5D";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                             }
                         },
                         
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m',
                        events: {
                            click:  () => {
                                this.currentRange = "M";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                            }
                        },
                         
                    }, {
                        type: 'month',
                        count: 3,
                        text: '3m',
                        events: {
                             click:  () => {
                                this.currentRange = "3M";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                            }
                         },
                         
                    }, {
                        type: 'month',
                        count: 6,
                        text: '6m',
                        events: {
                             click:  () => {
                                this.currentRange = "6M";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                            }
                         },
                         
                        
                    }, {
                        type: 'year',
                        count: 1,
                        text: '1Y',
                        events: {
                            click:  () => {
                                this.currentRange = "Y";
                                this.changeDataRange(this.currentRange);
                                
                                return false;
                            }
                        },
                        
                    }, {
                        type: 'day',
                        count: (3*365),
                        text: '3Y',
                        events: {
                            click:  () => {
                                this.currentRange = "3Y";
                                this.changeDataRange(this.currentRange);
                               return false;
                            }
                        },
                        
                         
                    }, {
                        type: 'year',
                        count: 5,
                        text: '5Y',
                        events: {
                            click:  () => {
                                 this.currentRange = "5Y";
                                this.changeDataRange(this.currentRange);
                                return false;
                            }
                        },
                        
                        
                    }, {
                        type: 'year',
                        count: 10,
                        text: '10Y',
                        events: {
                            click:  () => {
                                this.currentRange = "10Y";
                                this.changeDataRange(this.currentRange);
                                return false;
                                
                            }
                        },
                        
                    },
                     {
                        type: 'd',
                        count: 1,
                        text: 'Custom',
                        events: {
                            click:  () => {
                                this.currentRange = "custom";
                                this.selectCustomDateRange();
                                return false;

                               
                                
                            }
                        },
                        
                    }
                    
                    
                    ]
                },
                stockTools: {
                    gui: {
                        buttons: [ 'indicators', 'separator', 'simpleShapes', 'lines', 'crookedLines', 'measure', 'advanced', 'toggleAnnotations', 'separator', 'verticalLabels', 'flags', 'separator',  'fullScreen', 'typeChange', 'separator', 'currentPriceIndicator']
                    }

                },
                series: this.getSeriesConfig(),
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 800
                        },
                        chartOptions: {
                            rangeSelector: {
                                inputEnabled: false
                            }
                        }
                    }]
                },
                time: {
                   useUTC: false
                },
               
                 

            },
            items: [
                {
                    label: 'Share',
                    icon: 'pi pi-share-alt',
                    items: [
                        
                        {
                            label: 'Twitter',
                            icon: 'pi pi-twitter',
                            command: () => {
                                this.tweet();
                            }
                        },
                        {
                            label: 'Facebook',
                            icon: 'pi pi-facebook',
                            command: () => {
                                this.shareOnFacebook();
                            }
                        },
                    ]
                },
                {
                    label: 'Download',
                    icon: 'pi pi-download',
                    command: () => {
                        this.download();
                    }
                },
            
            ],

            chartMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.openSnapshotDialog();
                    }
                },
               
			],

            refetcher : null,//polls qm for latest data every 10 seconds
            newDataPointsFound: false, // indicator if there were new data points found by refetcher
            customStartDate: null,
            customEndDate: null,
        }
        

        
    },
    computed: {
        addDisabled() {
            return this.graphAssets.length >= 10;
        }
    },
    

    activated() {
       console.log("adv chart activated")
        this.searchType = this.searchTypes[0].items[3];
        if( this.currentSymbol != this.$props.selectedSymbol) {
            this.currentSymbol = this.$props.selectedSymbol;

            this.currentRange = "Y";
            
            
            if( this.$props.securityType == 'INDEX'){ 
                let a = this.$store.state.selectedMarketIndex;
                
                a['id'] = 'i'+a.marketIndexId;
                a['showVolume'] = false;
                this.graphAssets = [a];
            }
            else if( this.$props.securityType == 'EQUITY') {
                let a = this.$store.state.selectedEquity
                a['id'] = 'e'+a.securityId;
                a['showVolume'] = true;
                this.graphAssets = [a];
            }
            else {
                let a = this.$store.state.selectedFund
                 a['id'] = 'f'+a.fundId;
                 a['showVolume'] = true;
                this.graphAssets = [a];
            }

            
            this.clearChart();
            
            this.updateChart();
            
            
            
        }
      
        this.$refs.chartComponent.chart.reflow();
        this.startPollingPriceChange();
        

    },

    deactivated() {
        this.displaySnapshot = false;
        this.stopPollingPriceChange();
    },

    unmounted() {
        this.stopPollingPriceChange();
    },

    methods : {

        selectCustomDateRange() {
            this.$refs.customDateRangeModal.open( (selectedRange) => {
                console.log("selected dates are "+ JSON.stringify(selectedRange));
                this.customStartDate = selectedRange[0];
                this.customEndDate = selectedRange[1];
                this.liveUpdates = false;
                this.stopPollingPriceChange();
                this.changeDataRange(this.currentRange);
            });
        },

        startPollingPriceChange() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
			this.refetcher = setInterval(()=> {
				this.updateDataForCurrentRange();
			}, 10000);
		},
		stopPollingPriceChange() {
			if( this.refetcher ){
				clearInterval(this.refetcher);
			}
		},

        onToggleVolume(event) {//eslint-disable-line
            console.log("togingl vole")
            this.clearChart();
            this.updateChart();
        },
        onToggleLiveUpdate() {
            if( this.liveUpdates ) {
                this.startPollingPriceChange();
            }
            else {
                this.stopPollingPriceChange();
            }
        },
        clearChart() {
            
            this.updateYAxis(); 
            
            while(this.$refs.chartComponent.chart.series.length > 0){
                
                this.$refs.chartComponent.chart.series[0].remove(false); // to remove any indicators and other series
            }
           
           
        
            this.$refs.chartComponent.chart.annotations.forEach(annotation => annotation.destroy())
            this.$refs.chartComponent.chart.annotations.length = 0

            
        },

        updateYAxis() {
            let volumeAxisRequired = false;
            for( var i =0; i < this.graphAssets.length; ++i ) {
                if( this.graphAssets[i].showVolume ){
                    volumeAxisRequired = true;
                    break;
                }
            }

           
            while( this.$refs.chartComponent.chart.yAxis.length > 1){
                this.$refs.chartComponent.chart.yAxis[this.$refs.chartComponent.chart.yAxis.length-1].remove(false);
            }
            
           
            if( volumeAxisRequired ){
               
                this.$refs.chartComponent.chart.yAxis[0].update({
                    labels: {
                                align: 'left',
                                formatter: function() {
                                
                                    if( this.chart.dataPointToGraph && this.chart.dataPointToGraph == 'price' ) {
                                        return '$' + numeral(this.value).format('0.00');
                                    }
                                    else {
                                        return numeral(this.value).format('0.00%');
                                    }
                                },

                
                            },
                            id: "price",
                            height: '80%',
                            resize: {
                                enabled: true
                            },
                            gridLineDashStyle: 'dot'
                },false);
                this.$refs.chartComponent.chart.addAxis({
                    labels: {
                                align: 'left'
                            },
                            top: '80%',
                            height: '20%',
                            offset: 0,
                            visible: true,
                            id: 'volume',
                            
                }, false);
            }
            else {
               
                this.$refs.chartComponent.chart.yAxis[0].update({
                    labels: {
                                align: 'left',
                                formatter: function() {
                                
                                    if( this.chart.dataPointToGraph && this.chart.dataPointToGraph == 'price' ) {
                                        return '$' + numeral(this.value).format('0.00');
                                    }
                                    else {
                                        return numeral(this.value).format('0.00%');
                                    }
                                },

                
                            },
                            id: "price",
                            height: '100%',
                            resize: {
                                enabled: true
                            },
                            gridLineDashStyle: 'dot'
                },false);

               
            }
            
            
        },



        updateChart() {
            
            let colors = ['#33CC99', '#E63E3E', '#693BF5', '#FFBD53', '#5367FF', '#DA8C3C', '#584684', '#7CADC4', '#3DA85E', '#F4BF6A', '#DA3FBC'];
            if( this.graphAssets.length > 1 ) {
                // switch to percentage change
                this.dataPointToGraph = 'percent';
               
            }
            else {
                this.dataPointToGraph = 'price';
            }
            this.$refs.chartComponent.chart['dataPointToGraph'] = this.dataPointToGraph
            
            for( var i = 0; i < this.graphAssets.length; ++i ) {
                let asset = this.graphAssets[i];
                let seriesSet = null;
              
                let series = {
                    type: 'line',
                    id: asset.id+'price',
                    name: this.getQMSymbol(asset)  + ( this.dataPointToGraph == 'price'? " Price" : " %Change"),
                    color: colors[i],
                    data: [],
                    yAxis: 0,
                 
                   dataGrouping: {
                       enabled: true,
                   }
                   
                };
                
               
                let priceSeries = this.$refs.chartComponent.chart.addSeries(series);
                priceSeries['dataPointToGraph'] =  this.dataPointToGraph;
                priceSeries['id'] = asset.id+'price';
                
                let volSeries = null;
                if( asset.showVolume ) {
                    
                    let series2 = {
                        type: 'column',
                        id: asset.id+'vol',
                        name: this.getQMSymbol(asset) + " Volume ",
                        color: colors[i],
                        data: [],
                        yAxis: 1,
                        dataGrouping: {
                            enabled: true,
                        }
                    };
                   
                    volSeries = this.$refs.chartComponent.chart.addSeries(series2);
                    volSeries['id'] = asset.id+'vol';
                }
              
                seriesSet = {'price' : priceSeries, 'volume': volSeries};
                this.fetchData(this.currentRange, asset, seriesSet);
            }
        }, 

        getExchange(exchangeSymbol) {
            if( exchangeSymbol == 'XNAS') {
				return "NASDAQ";
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE";
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC";
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX";
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV";
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE"
			}
            else if( exchangeSymbol == 'ARCX'){
                return "NYSE ARCA"
            }
			else {
				return exchangeSymbol;
			}
        },

        getDataKey(row) {
            if( row.securityId) {
                return "e"+row.securityId;
            }
            else if( row.marketIndexId) {
                return "i"+row.marketIndexId;
            }
            else {
                return "f"+row.fundId;
            }
        },

        formatName(asset) {
            if( asset.securityId) {
                return asset.company;
            }
            else {
                return asset.name;
            }
        },

        removeAsset(asset) {
            if( asset.securityId) {
                this.graphAssets = this.graphAssets.filter( r => {
                    return r.securityId != asset.securityId;
                });
            }
            else if( asset.fundId) {
                this.graphAssets = this.graphAssets.filter( r => {
                    return r.fundId != asset.fundId;
                });
            }
            else {
                 this.graphAssets = this.graphAssets.filter( r => {
                    return r.marketIndexId != asset.marketIndexId;
                });
            }
            this.clearChart();
            this.updateChart();

        },


        notSelectedAsset(asset) {
            return this.selectedAsset != asset;
        },
      
        toggleFilterMenu(event) {
            this.$refs.filterMenu.toggle(event);
        },

		selectFilter(filter, event) {
            this.searchTypes[0].items.forEach((filterItem) => {
                if (filterItem === filter) {
                    filterItem.selected = true;
                    this.searchType = filterItem;
                } else {
                    filterItem.selected = false;
                }
            });

            this.$refs.menu.toggle(event);
            document.getElementById('advSecSearch').focus();
        },

        searchAssets(event){
            let type = (this.searchType.label == 'Market' ? 'All': this.searchType.label);
            ResearchService.queryMarket(type, event.query, 10, 0).then(response => {
				
                let marketQueryResults = response.data;
                
                if(this.searchType.label == 'Market') {
                   
                    
                     this.filteredAssets = [{
                        label: 'Equities', code: 'EQ', 
                        items: marketQueryResults.securities,
                    },
                    {
                        label: 'Funds', code: 'F', 
                        items: marketQueryResults.funds
                    },
                    {
                        label: 'Indices', code: 'I', 
                        items: marketQueryResults.indices
                    }];
                }
                else if( this.searchType.label == 'Equities'){
                    this.filteredAssets = [{
                        label: 'Equities', code: 'EQ', 
                        items: marketQueryResults.securities,
                    }]
                }
                else if( this.searchType.label == 'Funds'){
                    this.filteredAssets = [{
                        label: 'Funds', code: 'F', 
                        items: marketQueryResults.funds,
                    }];
                }
                else if( this.searchType.label == 'Indices'){
                    this.filteredAssets = [{
                        label: 'Indices', code: 'I', 
                        items: marketQueryResults.indices
                    }];
                }
          })
          .catch(error => {
              this.filteredAssets = []
              
            console.debug('There was an error:' +JSON.stringify(error))
          })
        },

		
		onAssetSelected(event) {
			let selected = event.value;
            
            if( selected.marketIndexId){ 
                this.selectedAsset = { name: `${selected.name}`};
                selected['showVolume'] = false;
                selected['id'] = 'i'+selected.marketIndexId;
            }
            else if( selected.securityId) {
                this.selectedAsset = { name: `${selected.symbol} - ${selected.company}`};
                selected['showVolume'] = false;
                selected['id'] = 'e'+selected.securityId;
            }
            else {
                this.selectedAsset = { name: `${selected.name}`};
                selected['showVolume'] = false;
                selected['id'] = 'f'+selected.fundId;
            }
            
            this.clearChart();
            this.graphAssets.push(selected);
           
            this.updateChart();

		},

		onAddSecInputBarClick() {
			this.selectedAsset = { name: ""};
		},

        changeDataRange(currentRange) {
            
            this.graphAssets.forEach(asset => {
                let seriesSet = this.findSeriesSet(asset);
                this.fetchData(currentRange, asset, seriesSet);
            });
        },

        updateDataForCurrentRange() {
            let calls = [];
            this.graphAssets.forEach(asset => {
                let seriesSet = this.findSeriesSet(asset);
                
                calls.push(this.fetchLatestChartData(asset, seriesSet));
            });

            Promise.allSettled(calls).then( () => {
                if( this.newDataPointsFound ) {
                    
                    this.$refs.chartComponent.chart.redraw();
                }
                this.newDataPointsFound = false;
            });
            
        },

        

        findSeriesSet(asset) {
            let allSeries = this.$refs.chartComponent.chart.series;
            let priceSeriesId = asset.id+"price";
            let volSeriesId = asset.id+"vol";
            
            let seriesSet = {price : null , volume: null};

            for( var i = 0; i < allSeries.length; ++i ) {
                let series = allSeries[i]; 
                
                if( series.id == priceSeriesId || series.userOptions.id == priceSeriesId) {
                    seriesSet.price = series;
                }
                else if( series.id == volSeriesId){
                    seriesSet.volume = series;
                }
                if( seriesSet.price && seriesSet.volume) {
                    break;
                }
            }
            return seriesSet;
        },
    

        fetchData( currentRange, asset, seriesSet) {
            switch( currentRange ) {
                case "D":
                    this.fetchDay(asset, seriesSet);
                    break;
                case "5D":
                    this.fetch5Days(asset, seriesSet);
                    break;
                case "M":
                    this.fetchEODData(asset, seriesSet,1, 'M');
                    break;
                case "3M":
                    this.fetchEODData(asset, seriesSet, 3, 'M');
                    break;
                case "6M":
                    this.fetchEODData(asset, seriesSet, 6, 'M');
                    break;
                case "Y":
                    this.fetchEODData(asset, seriesSet, 1,'Y');
                    break;
                case "3Y":
                    this.fetchEODData(asset, seriesSet, 3,'Y');
                    break;
                case "5Y":
                    this.fetchEODData(asset, seriesSet, 5,'Y');
                    break;
                case "10Y":
                    this.fetchEODData(asset, seriesSet, 10,'Y');
                    break;
                case "custom":
                    this.fetchCustomDateRangeData(asset, seriesSet);
                    break;
                default: 
                    this.fetchEODData(asset, seriesSet, 1,'Y');
                    break;

                


            }
        },


        fetchCustomDateRangeData(asset, seriesSet) {
            this.fetchChartData(asset, seriesSet,  10, 'custom', this.customStartDate, this.customEndDate);
        },
        

        getSeriesConfig() {
           
             return [{
                        type: 'line',
                        id: 'index',
                        name: ' Index',
                        color: '#33CC99',
                        data: []
                    }];
        },

        getXAxisConfig() {
            return [{
                   min: (() => {this.yearAgoMillis()})(),
                   max: (new Date()).getTime(),
                  
               }];
        },
        
        getYAxisConfig(){
           
                return [{
                        labels: {
                            align: 'left',
                            format: '${value:.0f}'
            
                        },
                        height: '80%',
                        resize: {
                            enabled: true
                        },
                        gridLineDashStyle: 'dot'
                    }, {
                        labels: {
                            align: 'left'
                        },
                        top: '80%',
                        height: '20%',
                        offset: 0
                    }];
            
        },

        getFormatterFunction() {
               
            return function (tooltip) {
                
                let s = tooltip.defaultFormatter.call(this, tooltip); // get the date to show at the bottom
                                        
                return [s[0]].concat(
                    this.points ?
                        this.points.map(function (point) {
                            if( point.series.name.includes('Volume')){
                                return point.series.name + ': '+ numeral(point.y).format('0,');
                            }
                            else {
                                let currency = " USD"
                                //console.log("checking point series name : "+ point.series.name);
                                if( point.series.name.includes(":CA") || point.series.name.includes(":AQL") ) {
                                    currency = " CAD"
                                }
                                if( point.series.dataPointToGraph && point.series.dataPointToGraph == 'price' ) {

                                    return point.series.name + ': $' + numeral( point.y).format('0,.00') + ' '+currency;
                                }
                                else {
                                    return point.series.name + ': ' + numeral( point.y).format('0.00%');
                                }
                            }
                        }) :[]
                );
            }
             
        },

        getPositionerFunction() {
            
            return function (width, height, point) {
                var chart = this.chart,
                    position;

                if (point.isHeader) {
                    position = {
                        x: Math.max(
                            // Left side limit
                            chart.plotLeft,
                            Math.min(
                                point.plotX + chart.plotLeft - width / 2,
                                // Right side limit
                                chart.chartWidth - width - chart.marginRight
                            )
                        ),
                        y: point.plotY
                    };
                } else {
                    position = {
                        x: point.series.chart.plotLeft,
                        y: point.series.yAxis.top - chart.plotTop
                    };
                }

                return position;
            }
           
        },

        getCurrency() {
            if( this.$props.selectedSymbol.includes(":") ) {
                return "CAD"
            }
            else {
                return "USD"
            }
        },

        startOfLastBusinessDay() {

            let now = new Date();
            if( isSaturday(now) ){
                return startOfDay(add(now, {days: -1}));
            }
            else if(isSunday(now)){
                return startOfDay(add(now, {days: -2}));
            }
            else {
                let open = setMinutes(setHours(new Date(), 10), 30);
                if(isAfter(now, open)) {
                    return startOfDay(now);
                }
                else {
                    return addBusinessDays(now, -1);
                }
            }
            
        },

        endOfLastBusinessDay() {

            let now = new Date();
            if( isSaturday(now) ){
                return endOfDay(add(now, {days: -1}));
            }
            else if(isSunday(now)){
                return endOfDay(add(now, {days: -2}));
            }
            else {
                return endOfDay(now);
            }
            
        },


        tommorrow() {

            let now = new Date();
            return add(now,{days:1});
            
        },

        fiveDaysAgo() {
            let now = new Date();
            return startOfDay(addBusinessDays(now, -5));
            
        },

        yearAgoMillis() {
            let now = new Date();
            return add(now,{years:-1}).getTime();
            
        },
       
        fetchDay(asset, seriesSet) {
           
            this.fetchChartData(asset, seriesSet, 5, 'D', this.startOfLastBusinessDay(), null);
        },
        fetch5Days(asset, seriesSet) {
            
            this.fetchChartData(asset, seriesSet,  10, 'D', this.fiveDaysAgo(), null);
        },
        
        fetchEODData(asset, seriesSet,offset, offsetType) {
            
            let startDate = new Date();
           

            if( offsetType == 'M'){
                startDate = add(startDate, {months: -offset});
               

            } else {
                startDate = add(startDate, {years: -offset});
               

            }
            if( isSaturday(startDate) ){
                startDate = startOfDay(add(startDate, {days: -1}));
            }
            else if(isSunday(startDate)){
                startDate = startOfDay(add(startDate, {days: -2}));
            }
            
            this.fetchChartData(asset, seriesSet, offset, offsetType, startDate, this.endOfLastBusinessDay());
            
        },

        getQMSymbol(asset) {
			
			let symbol = asset.symbol;
			if( asset.marketIndexId){ 
                return "^"+symbol;
            }
            else {
                return SecurityFormatter.getSymbolViaCdnExchanges(asset);
            }
		},


        fetchLatestChartData(asset, seriesSet) {
           
            return new Promise((resolve, reject) => {  //eslint-disable-line
                let selectedSymbol = this.getQMSymbol(asset);
                let  firstDataPoint = seriesSet.price.firstDataPoint;
                
                let lastDataPointTime = seriesSet.price.options.data[seriesSet.price.options.data.length-1][0];
                let symbolParam = selectedSymbol;
                let startDate = new Date(lastDataPointTime);

                DataService.getLatestPriceSince( symbolParam, format(startDate, 'yyyy-MM-dd')).then(response => {
                    
                    

                    let results = response.data.results;
                    
                    try {

                        if( results.intraday[0].interval && results.intraday[0].interval.length > 0 ) {
                            
                            let d  = parseISO(results.intraday[0].interval[0].startdatetime);
                            
                            if( d.getTime() > lastDataPointTime) {
                               
                                this.newDataPointsFound = true;
                                if( this.dataPointToGraph == 'price') {
                                    
                                    seriesSet.price.addPoint([
                                        (d).getTime(), // the date
                                        results.intraday[0].interval[0].adjustedopen, // open
                                        results.intraday[0].interval[0].adjustedhigh, // high
                                        results.intraday[0].interval[0].adjustedlow, // low
                                        results.intraday[0].interval[0].adjustedclose // close
                                    ], false);
                                }
                                else {
                                   
                                    seriesSet.price.addPoint([
                                        (d).getTime(), // the date
                                        (results.intraday[0].interval[0].adjustedclose/firstDataPoint)-1, // %change
                                    
                                    ], false);
                                }
                                if( seriesSet.volume){
                                    seriesSet.volume.addPoint([
                                    d.getTime(), // the date
                                    results.intraday[0].interval[0].sharevolume // the volume
                                    ], false);
                                }
                                
                                
                            }
                        }

                    }catch( error ) {
                        console.debug("error getting latest data from server:"+error);
                        

                    }
                });
                resolve(asset);
            });

        },

        fetchChartData(asset, seriesSet, interval, intervalType, startDate, endDate) {
            let selectedSymbol = this.getQMSymbol(asset);
            
            this.$refs.chartComponent.chart.showLoading('Loading data from server...');
            
            let symbolParam = selectedSymbol;
           
            DataService.getPriceHistory( symbolParam, interval, intervalType, 
                                                format(startDate, 'yyyy-MM-dd'), (endDate != null) ? format(endDate, 'yyyy-MM-dd') : null)
                                                .then(response => 
                        {
                            let results = response.data.results;
                            console.log(results);
                           
                            try {
                                if( intervalType == 'D' || (intervalType == 'M' && interval == 1)) {
                                    this.updateSeriesWithDelayedData(results, asset, seriesSet);
                               }
                                else {
                                   this.updateSeriesWithEODData(results, asset, seriesSet);
                                }
                                
                                this.$refs.chartComponent.chart.hideLoading();
                
                                this.$refs.chartComponent.chart.xAxis[0].setExtremes(
                                    startDate.getTime(),
                                    (endDate!=null) ? endOfDay(endDate).getTime() : endOfDay(new Date()).getTime(),
                                    true
                                );
                                
                                this.updateButtonStyling(interval, intervalType);

                                const series = this.$refs.chartComponent.chart.series;
                                const indicators = series.filter((series) => {
                                    return series.userOptions.linkedTo
                                });

                                if (indicators.length > 0) {
                                    indicators.forEach((indicator) => {
                                        const indicatorCopy = indicator.userOptions;
                                        const indicatorPosition = this.$refs.chartComponent.chart.series.indexOf(indicator);

                                        this.$refs.chartComponent.chart.series[indicatorPosition].remove();
                                        this.$refs.chartComponent.chart.addSeries(indicatorCopy);
                                    })
                                }

                            }catch( error ) {
                                console.debug("error getting data from server:"+error);
                                console.error(error);
                                this.$refs.chartComponent.chart.hideLoading();

                            }
                        }
                        
                    );
           
        },

        updateButtonStyling(interval, intervalType) {
            let target = 1;
            if( this.currentRange == 'custom') {
                target = 10;
            }
            else if( intervalType == 'D'){
                if( interval == 5 ) {
                    target = 1; // D button
                }
                else {
                    target = 2; // 5 D button
                }
            }
            else if( intervalType == 'M')  {
                if( interval == 1 ) {
                    target = 3; // 1 month button
                }
                else if( interval == 3){
                    target = 4; // 3 month button
                }
                else if( interval == 6) {
                    target = 5; // // 6 month button
                }
            }
            else {
                if( interval == 1) {
                    target = 6; // 1 year button
                }
                else if( interval == 3){
                    target = 7; // 3 year button
                }
                else if( interval == 5) {
                    target = 8; // 5 year button
                }
                else {
                    target = 9; // 10 year button
                }
            }
            var i = 1;
            while( i <= 10 ) {
                let b = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[i];
                
                if( i == target) {
                    b.className.baseVal= "highcharts-button highcharts-button-pressed invrs-chart-button-pressed";
                    
                    
                }
                else {
                   
                    b.className.baseVal= "highcharts-button highcharts-button-normal";
                }
                ++i;
                
            }
        },

        extractDelayedDataPoints(results, price, volume) {
            
            var dataLength = results.intraday[0].interval.length;
            var i = dataLength-1;
            var firstDataPoint = null;
            while( i >= 0 ) {
                let d  = parseISO(results.intraday[0].interval[i].startdatetime);
                if( i == results.intraday[0].interval.length-1) {
                    firstDataPoint = results.intraday[0].interval[i].adjustedclose;
                }
                
                if( this.dataPointToGraph == 'price') { // we're charting price
                    price.push([
                        (d).getTime(), // the date
                        results.intraday[0].interval[i].adjustedopen, // open
                        results.intraday[0].interval[i].adjustedhigh, // high
                        results.intraday[0].interval[i].adjustedlow, // low
                        results.intraday[0].interval[i].adjustedclose // close
                    ]);
                }
                else { // we're charting %change
                    
                    price.push([
                        (d).getTime(), // the date
                        (results.intraday[0].interval[i].adjustedclose/firstDataPoint)-1, // %change
                       
                    ]);
                }
                
                volume.push([
                    d.getTime(), // the date
                    results.intraday[0].interval[i].sharevolume // the volume
                    ]);

                --i;
            }
            return firstDataPoint;
        },


        enableGrouping(seriesSet) {
            let currentPriceSeriesId = seriesSet.price.id ? seriesSet.price.id : seriesSet.price.userOptions.id;
                
            seriesSet.price.update(
                {
                    dataGrouping: {
                        enabled: true,
                    }
                }, false);
            seriesSet.price['id'] = currentPriceSeriesId; // have to do this as the call to update wipes out the id field which is custom
            seriesSet.price['dataPointToGraph'] = this.dataPointToGraph;

            if( seriesSet.volume ) {
                let currentVolSeriesId = seriesSet.volume.id;
                seriesSet.volume.update(
                    {
                        dataGrouping: {
                            enabled: true,
                        }
                    }, false);
                seriesSet.volume['id'] = currentVolSeriesId;
                seriesSet.volume['dataPointToGraph'] = this.dataPointToGraph;
            }
        },

        disableGrouping(seriesSet) {

            let currentPriceSeriesId = seriesSet.price.id;
                
            seriesSet.price.update(
                {
                    dataGrouping: {
                        enabled: false,
                    }
                }, false);
            seriesSet.price['id'] = currentPriceSeriesId; // have to do this as the call to update wipes out the id field which is custom
            seriesSet.price['dataPointToGraph'] = this.dataPointToGraph;

            if( seriesSet.volume ) {
                let currentVolSeriesId = seriesSet.volume.id;
                seriesSet.volume.update(
                    {
                        dataGrouping: {
                            enabled: false,
                        }
                    }, false);
                seriesSet.volume['id'] = currentVolSeriesId;
                seriesSet.volume['dataPointToGraph'] = this.dataPointToGraph;
            }
                
        },

        updateSeriesWithDelayedData(results, asset, seriesSet) {
            var price = [];
            var volume = [];
            
            let firstDataPoint = this.extractDelayedDataPoints(results, price, volume);

            if( this.currentRange == 'D' || this.currentRange == '5D' || this.currencyRange == 'custom') {
                // turn off grouping for current day and 5 day so we can see by the minute data points.
                this.disableGrouping(seriesSet);
                
            }
            else {
                this.enableGrouping(seriesSet);
               
               
            }


            seriesSet.price.setData(price, false);
            
            seriesSet.price['firstDataPoint'] = firstDataPoint;
            
            if( asset.showVolume ){
                
                seriesSet.volume.setData(volume, false);
            }
           
        },

        extractEODDataPoints(results, price, volume) {
            var dataLength = results.history[0].eoddata.length;
            var i = dataLength-1;
            var firstDataPoint = null;
            let now = new Date();
            while (i >= 0 ) {
                if( i == results.history[0].eoddata.length-1) {
                    firstDataPoint = results.history[0].eoddata[i].close;
                }
                 
                let d  = parse(results.history[0].eoddata[i].date, 'yyyy-MM-dd', now);
               
               if( this.dataPointToGraph == 'price') { // we're charting price or index value
                   let p = [
                        d.getTime(), // the date
                        results.history[0].eoddata[i].open, // open
                        results.history[0].eoddata[i].high, // high
                        results.history[0].eoddata[i].low, // low
                        results.history[0].eoddata[i].close // close
                    ];
                  
                    price.push(p);
               }
               else { // we're charting %change
                   
                 price.push([
                        (d).getTime(), // the date
                        (results.history[0].eoddata[i].close/firstDataPoint)-1
                       
                       
                    ]);
               }
                
                volume.push([
                    d.getTime(), // the date
                    results.history[0].eoddata[i].sharevolume // the volume
                    ]);
                
                --i;
            }
            return firstDataPoint;
        }, 

        updateSeriesWithEODData(results, asset, seriesSet) {
            
            var price = [];
            var volume = [];

            let firstDataPoint = this.extractEODDataPoints(results, price, volume)
            
            this.enableGrouping(seriesSet);
            
            
           
            seriesSet.price.setData(price, false);
            seriesSet.price['firstDataPoint'] = firstDataPoint;
           
            if(  asset.showVolume ){
               
                seriesSet.volume.setData(volume, false);
            }
        
        },

        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },

        

        openSnapshotDialog() {
          
            this.snapshotTarget = this.$refs.chartComponent.$el.getElementsByClassName('highcharts-container')[0];
            this.displaySnapshot = true;
          
           
        },
        onInputBarClick() {
            this.clearInput();
        },

         clearInput() {
          
            this.selectedAsset = null;
            this.selectedItem = { displayText: ""};
            this.selectedUser = null;
            document.getElementById('advSecSearch').focus();
        }

        
        
    }
    
  }
</script>

<style scoped>

.metrics-header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
    background: #fff;
    font-weight: 400;
    color: #999;
    font-size: 14px;
}

.live-update {
    color: #999;
}

::v-deep(.highcharts-scrollbar) {
    display: none;
}

.header-options {
    display: flex;
    align-items: center;
}

.p-input-icon-right {
    margin-right: 16px;
    display: inline-flex;
}

::v-deep(.p-input-icon-right){
	top:25%;
}

::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	width:21rem;
	/* border-radius: 8px; */
    border-bottom-left-radius:8px;
    border-top-left-radius:8px;
    border-bottom-right-radius:0px;
    border-top-right-radius:0px;
}
::v-deep(.p-inputtext:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(.p-inputtext:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}
.tab-content {
	/* background:#17212f; */
	padding:10px;
}

.statement-title {
	font-size: 26px;
	color: #32364e;
}


::v-deep(.p-autocomplete > input ){
   
    text-indent: 0rem;
    /* width: 11rem; */
}




::v-deep(div.highcharts-popup-lhs-col > ul > li:nth-child(13)){ /*hide chaikin osc indicator*/
    display:none;
}
::v-deep(div.highcharts-popup-lhs-col > ul > li:nth-child(39)){ /*hide Williams %R indicator*/
    display:none;


}

::v-deep(div.highcharts-popup-lhs-col > ul > li:nth-child(41)){ /*hide Zig Zag indicator*/
    display:none;

}
::v-deep(div.highcharts-popup-lhs-col > ul > li:nth-child(46)){ /*hide Accelator bands indicator*/
    display:none;

}


.advChart {
    max-height: 800px;
	min-height: 75vh;

}

.more-options{
    position: absolute;
    right: 10px;
    top: 0px;
    z-index: 8;
}


/* Begin GUI tools */

/* ::v-deep(.highcharts-annotation-shapes > circle){
    fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
} */

/* ::v-deep(.highcharts-annotation-shapes > path){
     fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
    stroke-width: 3;
} */


/* end GUI tools */

/* the range selector buttons*/

::v-deep(.invrs-chart-button-pressed > rect) {
    background-color: #32364E;
    fill: #32364E;
  
}

::v-deep(.invrs-chart-button-pressed >  text) {

      color:#ffffff !important;
      fill: #ffffff !important;
    font-weight: normal !important;
}
/* ::v-deep(g.highcharts-range-selector-group > g.highcharts-range-selector-buttons > g.highcharts-button.highcharts-button-normal > rect.highcharts-button-box){

    background-color: #fff;
    border: 1px solid #32364E;
    border-radius: 4px;
    margin-right: 5px;
    fill: #fff;
    stroke: #32364E;

} */

::v-deep( g.highcharts-range-selector-group > g.highcharts-input-group > g.highcharts-label.highcharts-range-input > rect.highcharts-label-box){
    rx: 2;
    ry: 2;
}

::v-deep( g.highcharts-range-selector-group >   g.highcharts-range-selector-buttons > g.highcharts-label){
    display:none;
}

::v-deep(.highcharts-range-label) {
    color: #33CC99;
}


::v-deep(g.highcharts-range-selector-group > g.highcharts-range-selector-buttons > g:nth-child(11) > rect){
    width: 5rem!important;

}



::v-deep(g.highcharts-range-selector-group > g.highcharts-range-selector-buttons > g:nth-child(11) > text){
    transform: translateX(1.2rem);
}


::v-deep(g.highcharts-range-selector-group > g.highcharts-range-selector-buttons > g:nth-child(11) > rect){
    width: 5rem!important;
}


::v-deep(g.highcharts-range-selector-group > g.highcharts-range-selector-buttons > g:nth-child(11) > text){
    transform: translateX(1.2rem);
}

/** hide the highcharts logo */
::v-deep(.highcharts-credits) {
	
    display:none;
}

::v-deep(.highcharts-title) {
    color: #32364E !important;
    fill: #32364E !important;
}

::v-deep(.highcharts-subtitle) {
    color: #32364E !important;
    fill: #32364E !important;
}


/** highcharts popup styling */
::v-deep(.highcharts-popup button) {
   
    padding: 0.75rem 1rem;
    
}

:v-deep(.highcharts-popup button:hover) {
    background: #e9ecef;
}

::v-deep(.highcharts-popup-bottom-row) {
    /* //float: left;
    /* padding: 0px 20px; */
    /* width: calc(100% - 40px);  */
    padding: 0.5rem;
}

::v-deep(.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li ){
    border-radius:4px !important;
}
::v-deep(.highcharts-menu-item-btn) {
    border-radius:4px !important;
}

::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background : #32364E;
}

::v-deep(.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider) {
     background : #32364E;
}

::v-deep( .p-inputswitch.p-focus .p-inputswitch-slider){
    
    box-shadow: none;
}
::v-deep(.adv-drop-button) {
    background-color: #32364E;
    border: 1px solid #32364E;
}

::v-deep(.adv-drop-button:enabled:hover) {
    background: #32364E;
    
    border-color: #32364E;
} 


::v-deep(.delete-note-button) {
    border: 1px solid #fa0505;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    /* margin-left: 8px; */
}

::v-deep(.delete-note-button:hover) {
    border-color:#fa0505;
    background-color: #fa0505;
    color: #FFFFFF;
}
::v-deep(.delete-note-button:focus) {
    box-shadow: none;
    border-color: #fa0505;
}
</style>